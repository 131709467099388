<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Tambah <b>Pemakaian Paket Layanan</b></div>
    </b-alert>

    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit.stop.prevent="formOnsubmit">
              <div class="row">
                <div class="col-md-6">
                  <!-- Input Modal Trigger -->
                  <b-form-group
                    id="input-group-trigger-modal-patient"
                    label="Pasien:"
                    label-for="input-trigger-modal-patient"
                  >
                    <b-form-input
                      id="input-trigger-modal-patient"
                      v-model="display.patient_name"
                      placeholder="Pilih Pasien"
                      readonly
                      @click="$bvModal.show('modal-patient')"
                    >
                    </b-form-input>
                    <small class="text-danger">{{ error.patient_id }}</small>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <!-- Input  Operator-->
                  <b-form-group
                    id="input-group-operator"
                    
                  >
                    <label for="input-operator">Layanan:</label>
                    <treeselect
                      @select="setService"
                      @deselect="removeService"
                      v-model="form.service_id"
                      placeholder="Pilih Layanan"
                      :options="paidPackage"
                    
                    />

                    <!-- <treeselect
                      @select="paidPackage"
                      @deselect="removeOperator"
                      v-model="form.service_id"
                      :multiple="true"
                      placeholder="Pilih Layanan"
                      :options="paidPackage"
                    /> -->
                    <!-- <small class="text-danger">{{error.}}</small> -->
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <!-- Input Doctor -->
                  <b-form-group
                    id="input-group-doctor"
                    label="Dokter:"
                    label-for="input-doctor"
                  >
                    <treeselect
                      v-model="form.doctor_id"
                      :multiple="true"
                      :options="doctors"
                      placeholder="Pilih Dokter"
                    />
                    <small class="text-danger">{{ error.doctor_id }}</small>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  
                  <b-form-group
                    id="input-group-nurse"
                    label="Perawat:"
                    label-for="input-nurse"
                  >
                    <treeselect
                    
                      v-model="form.nurse_id"
                      :multiple="true"
                      :options="nurses"
                      placeholder="Pilih Perawat"
                    />
                    <small class="text-danger">{{ error.nurse_id }}</small>
                  </b-form-group>
                </div>
              </div>

              <div class="row justify-content-end">
                
                  <button type="submit" class="btn btn-primary m-1">Simpan</button>
                  <button type="button" class="btn btn-secondary m-1">Batal</button>
                
              </div>


            </b-form>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        
        <div class="card" v-if="form.patient_id != ''">
          <div class="card-body">
            <div class="card-title">
              <span>
                Detail Penggunaan Paket -   
              <b>
                {{display.patient_name}}
              </b></span>
            </div>

            <b-table
              striped
              hover
              class="mt-3"
              responsive
              :items="serviceList"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }"
                />
              </template>
              <!-- detail collapse -->
              <template #cell(payment_category_price)="data">
                {{parseInt(data.item.payment_categories_price).toLocaleString('id-ID')}}
              </template>
              <template #row-details="data">
                <div class="card">
                  <div class="card-body">
                    <ul>
                      <li v-for="(val,index) in data.item.used_item" :key="index">
                        
                        <span class="d-block">{{val.name_display}}
                          <span class="text-primary" v-if="val.status == 0"> ({{val.status_display}})</span>
                          <span class="text-success" v-if="val.status == 1"> ({{val.status_display}})</span>
                        </span>
                        <small class="d-block" v-if="val.status == 1"><b>Digunakan Pada {{val.used_date_display}}</b></small>
                      </li>
                    </ul>    
                  </div>
                </div>
              </template>
              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Info"        
                  @click="data.toggleDetails"
                ><i class="fas fa-info-circle px-0"></i></b-button>
              </template>
              <!-- end detail collapse -->
            </b-table>
          </div>
        </div>

      </div>

    </div>

    <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/component/masters/payment-categories/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ModalPatient from "@/component/general/ModalPatient.vue";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";

export default {
  data() {
    return {
      form: {
        patient_id: this.$route.query.patient_id ?? "",
        service_id: "",
        doctor_id: [],
        nurse_id:[],
        // patient_name: this.$route.query.patient_name ?? '',
        // doctor_id: this.$route.query.doctor_id ?? '',
        // appointment_id: this.$route.query.appointment_id ?? '',
        // patient_age: "",
        // electro_laser: "",
        // action_type: "",
        // operator: "",
        // responsible_doctor:"",
        // assistant: "",
        // anesteshia: "",
        // anesthetist: null,
        // notes: "",
        // nurse_notes: "",
        // medicines: "",
        // date: new Date().toISOString().substr(0, 10),
      },
      fields:[
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "buy_date",
          label: 'Tanggal Pembelian',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "payment_category_price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "action",
          label: "",
        },
      ],
      error: {
        patient_id: "",
        doctor_id: "",
        nurse_id:""
      },
      // data
      serviceList:[],
      paidPackage: [],
      doctors: [],
      nurses:[],


      // Display Data
      display: {
        patient_name: "",
        // assistant_name: "",
        // omploop_name: "",
        // nurse_action_name: "",
        // medicine_name: "",
        // medicine_takeaway: "",
      },
    };
  },

  components: {
    Card,
    Form,
    ModalPatient,
  },

  methods: {
    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
      this.getPackage(value.id);
      
    },

    async getPackage(id) {
      let dataResponse = await module.get("package-service-paid/" + id)
      let response = await module.setChildrenTreeSelect("package-service-paid/" + id);
      // Success
      if(dataResponse != []){
        this.serviceList = dataResponse
      }

      if (response.state == "success") {
        // Set Option Select
        this.paidPackage = response.data;
        this.paidPackage.unshift({
          label: "Pilih Layanan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getDoctorOptions() {
      let route;
      // if (typeof this.form.patient_id !== 'undefined' && this.form.patient_id != '') {
      //   route = `doctors/get-by-patient/${this.form.patient_id}`
      // } else {
      route = "doctors";
      // }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getNurseOptions() {
      let route;
      // if (typeof this.form.patient_id !== 'undefined' && this.form.patient_id != '') {
      //   route = `doctors/get-by-patient/${this.form.patient_id}`
      // } else {
      route = "employee/nurse";
      // }
      let response = await module.setTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.nurses = response.data;
        this.nurses.unshift({
          label: "Pilih Perawat",
          id: "",
          isDisabled: true,
        });
      }
    },

    setService() {},
    removeService() {},
  },

  mounted() {
    this.getDoctorOptions();
    this.getNurseOptions();
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Layanan", route: "" },
      { title: "Daftar Pemakaian Paket Layanan", route: "/used-package-service/list" },
      { title: "Tambah" },
    ]);
  },
};
</script>